



















































import Vue from 'vue';
import Separator from './utilities/Separator.vue';
import ExperienceItem from './utilities/ExperienceItem.vue';
export default Vue.extend({
    components: {
        Separator,
        ExperienceItem
    }
});
