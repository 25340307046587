








import Vue from 'vue';
export default Vue.extend({
    computed: {
        year: function () {
            const year = new Date().getFullYear();
            if (year > 2019) {
                return `2019 - ${year}`;
            }
            else {
                return "2019";
            }
        }
    }
});
