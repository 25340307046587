



















import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);
export default Vue.extend({
    data () {
        return {
            smallHeader: false
        }
    },
    methods: {
        handleScroll (event: Event) {
            const headerElem = document.querySelector('.navbar.navbar-dark') as HTMLElement;
            if (headerElem) {
                if (window.scrollY > headerElem.offsetHeight) {
                    this.smallHeader = true;
                }
                else {
                    this.smallHeader = false;
                }
            }
        },
        scrollTop () {
            const primeNav = document.querySelector('#primaryNavigation');
            if (primeNav) {
                const classList = primeNav.getAttribute('class');
                if (classList && classList.indexOf('show') !== -1) {
                    const primeNavRef = this.$refs.primaryNavigation as any;
                    primeNavRef.toggle();
                }
            }
        }
    },
    created: function () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed: function () {
        window.removeEventListener('scroll', this.handleScroll);
    }
});
