



















































import Vue from 'vue';
import Separator from './utilities/Separator.vue';
export default Vue.extend({
    components: {
        Separator
    }
});
