













import Vue from 'vue';
export default Vue.extend({
    props: {
        experienceTitle: String,
        experienceSubtitle: String,
        description: String,
        startDate: String,
        endDate: String
    }
});
